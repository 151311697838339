import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';

const StyledImg = styled(Img)`
  z-index: -1;
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export default ({ image, title }) => (
  <StyledImg fluid={image} title={title} alt={title} objectPosition="50% 50%" />
);
