import React from 'react';

export default ({
  className,
  title,
  src,
  border = '0',
  width = '100%',
  height = '180'
}) => {
  return (
    <iframe
      className={className}
      title={title}
      src={src}
      frameBorder={border}
      width={width}
      height={height}
    />
  );
};
