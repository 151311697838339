import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import HeroImage from './HeroImage';
import HeroContent from './HeroContent';
import device from '../../../Common/Device';

const OuterContainer = styled.div`
  position: relative;
  z-index: 2;
`;

const HeroContainer = styled.div`
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  padding: 120px 0;

  @media ${device.max.xs} {
    padding: 64px 0;
  }
`;

export default ({
  header,
  subheader,
  episodeNumber,
  episodeDate,
  image,
  isContentCenter,
  logoImg,
  logoTitle,
  spotifyUrl,
  appleUrl,
  googleUrl
}) => {
  return (
    <OuterContainer>
      <HeroContainer>
        <HeroImage image={image} title={header} />
        <div className="container-lg">
          <Row>
            <Col
              lg={isContentCenter ? '12' : '6'}
              md={isContentCenter ? '12' : '7'}
              xs={12}>
              <HeroContent
                episodeNumber={episodeNumber}
                episodeDate={episodeDate}
                logoImg={logoImg}
                logoTitle={logoTitle}
                header={header}
                subheader={subheader}
                isContentCenter={isContentCenter}
                spotifyUrl={spotifyUrl}
                appleUrl={appleUrl}
                googleUrl={googleUrl}
              />
            </Col>
          </Row>
        </div>
      </HeroContainer>
    </OuterContainer>
  );
};
