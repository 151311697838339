import React from 'react';
import styled from 'styled-components';
import Link from '../../Common/Link';
import Spotify from '../../../images/assets/spotify.svg';
import ApplePodcasts from '../../../images/assets/apple_podcasts.svg';
import GooglePodcasts from '../../../images/assets/google_podcasts.svg';
import device from '../../Common/Device';

const Links = styled.div`
  display: flex;
  text-align: left;

  div {
    > a {
      color: ${props => props.color};

      :hover {
        color: ${props => props.color};
      }
    }

    svg {
      margin-right: 8px;
      margin-bottom: 3px;

      path {
        fill: ${props => props.color};
      }
    }

    :last-child svg path {
      stroke: ${props => props.color};
    }
  }

  ${props =>
    props.isVertical
      ? `
    flex-direction: column;
  `
      : `
    flex-direction: row;
    justify-content: center;

    div {
      padding: 0 16px;

      :first-child {
        padding-left: 0;
      }

      :last-child {
        padding-right: 0;
      }

      :not(:last-child) {
        border-right: 1px solid #e5e5e5;
      }
    }

    @media ${device.max.sm} {
      flex-direction: column;
      text-align: center;

      div {
        padding: 16px 0;
        border: 0 !important;

        :first-child {
          padding-top: 0;
        }

        :last-child {
          padding-bottom: 0;
        }
      }
    }
  `}
`;

export default ({
  className,
  spotifyUrl,
  appleUrl,
  googleUrl,
  isVertical = false,
  color = '#fff'
}) => {
  return (
    <Links className={className} color={color} isVertical={isVertical}>
      <div>
        <Link to={spotifyUrl}>
          <Spotify />
          Listen on Spotify
        </Link>
      </div>
      <div>
        <Link to={appleUrl}>
          <ApplePodcasts />
          Listen on Apple Podcasts
        </Link>
      </div>
      <div>
        <Link to={googleUrl}>
          <GooglePodcasts />
          Listen on Google Podcasts
        </Link>
      </div>
    </Links>
  );
};
