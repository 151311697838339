import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { LabelsContainer } from '@vancity/react-component-library';
import StreamingServices from '../StreamingServices';
import device from '../../../Common/Device';

const HeroContent = styled.div`
  text-align: ${props => (props.alignCenter ? 'center' : 'left')};
  color: #fff;

  p:last-child {
    margin: 0;
  }
`;

const StyledLabelsContainer = styled(LabelsContainer)`
  justify-content: ${props => (props.alignCenter ? 'center' : 'left')};
`;

const Subheader = styled.p`
  max-width: 600px;
  margin: 0 auto 24px auto;
`;

const StyledStreamingServices = styled(StreamingServices)`
  @media ${device.max.xs} {
    margin-bottom: 40px;
  }
`;

export default ({
  logoImg,
  logoTitle,
  episodeNumber,
  episodeDate,
  header,
  subheader,
  isContentCenter = false,
  spotifyUrl,
  appleUrl,
  googleUrl
}) => {
  return (
    <HeroContent alignCenter={isContentCenter}>
      {logoImg && <Img fixed={logoImg} alt={logoTitle} />}
      {episodeNumber && (
        <StyledLabelsContainer
          alignCenter={isContentCenter}
          firstText={episodeNumber}
          secondText={episodeDate}
        />
      )}
      <h1>{header}</h1>
      <Subheader>{subheader}</Subheader>
      {spotifyUrl && appleUrl && googleUrl && (
        <StyledStreamingServices
          spotifyUrl={spotifyUrl}
          appleUrl={appleUrl}
          googleUrl={googleUrl}
        />
      )}
    </HeroContent>
  );
};
