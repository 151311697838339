import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Section from '../../Common/Section';
import SectionHeader from '../../Common/Header/SectionHeader';
import IFrame from '../Common/IFrame';
import LearMore from '../../Common/LearnMore';
import StreamingServices from '../Common/StreamingServices';

const StyledRow = styled(Row)`
  justify-content: center;
  text-align: center;
`;

const Content = styled.div`
  text-align: left;
  margin-top: 40px;

  span:first-child {
    margin: 0 0 8px 0;
    display: block;
  }

  p:last-child {
    margin: 0;
  }

  div div {
    margin-bottom: 8px;
  }

  h5 {
    margin-top: 40px;
  }
`;

export default ({
  header,
  title,
  embedSrc,
  spotifyUrl,
  appleUrl,
  googleUrl,
  transcriptUrl,
  body
}) => {
  return (
    <Section>
      <div className="container-lg">
        <StyledRow>
          <Col lg={8} md={8}>
            <SectionHeader header={header} />
            <IFrame title={title} src={embedSrc} />
            <Content>
              <span>Other ways to listen:</span>
              <StreamingServices
                color="#007b86"
                spotifyUrl={spotifyUrl}
                appleUrl={appleUrl}
                googleUrl={googleUrl}
                isVertical
              />
              <LearMore
                text="Read the transcript"
                url={transcriptUrl}
                external
                openNewTab
              />
              <h5>In this episode</h5>
              {body && renderRichText(body)}
            </Content>
          </Col>
        </StyledRow>
      </div>
    </Section>
  );
};
