import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import PodcastEpisode from '../components/Podcast/Episode/PodcastEpisode';

// Page query
export const query = graphql`
  query($slug: String!) {
    contentfulSpecialPodcastEpisode(pagePath: { eq: $slug }) {
      contentful_id
      metaTitle
      metaDescription
      metaKeywords
      pagePath
      featureImage {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyContentfulFluid
        }
      }
      episodeNumber
      episodeDate(formatString: "MMM DD, YYYY")
      episodeTitle
      episodeShortDescription
      embedSource
      spotifyLink
      appleLink
      googleLink
      transcript {
        file {
          url
          fileName
        }
      }
      inThisEpisode {
        raw
      }
      promotion {
        header
        body {
          raw
        }
        ctaText
        ctaLink {
          url
        }
      }
      moreEpisodes {
        episodeNumber
        episodeTitle
        episodeShortDescription
        pagePath
      }
    }
  }
`;

export default ({ data }) => {
  const episodeData = data.contentfulSpecialPodcastEpisode;

  return (
    <Layout currentPage="Podcast">
      <SEO
        title={episodeData.metaTitle}
        description={episodeData.metaDescription}
        keywords={episodeData.metaKeywords.split(', ')}
      />
      <PodcastEpisode data={episodeData} />
    </Layout>
  );
};
