import React from 'react';
import {
  CtaSection,
  EpisodeCardsSection
} from '@vancity/react-component-library';
import Sections from '../../Common/Sections';
import Hero from '../Common/Hero/Hero';
import EpisodeSection from './EpisodeSection';

export default ({ data }) => {
  return (
    <>
      <Hero
        episodeNumber={`Episode ${data.episodeNumber}`}
        episodeDate={data.episodeDate}
        header={data.episodeTitle}
        subheader={data.episodeShortDescription}
        image={data.featureImage.fluid}
      />
      <Sections oddBackground="#fff" evenBackground="#F7F7F7">
        <EpisodeSection
          header={`Episode ${data.episodeNumber}`}
          title={data.episodeTitle}
          embedSrc={data.embedSource}
          spotifyUrl={data.spotifyLink}
          appleUrl={data.appleLink}
          googleUrl={data.googleLink}
          transcriptUrl={data.transcript.file.url}
          body={data.inThisEpisode}
        />
        <CtaSection
          header={data.promotion.header}
          body={data.promotion.body}
          primaryCta={data.promotion.ctaText}
          primaryCtaUrl={data.promotion.ctaLink.url}
        />
        <EpisodeCardsSection
          header="Other episodes"
          items={data.moreEpisodes}
        />
      </Sections>
    </>
  );
};
